import {
  SET_SIGN_IN_OPEN,
  SET_SIGN_IN_CLOSE,
  SET_SIGN_UP_OPEN,
  SET_SIGN_UP_CLOSE,
  SET_RESET_PASSWORD_OPEN,
  SET_RESET_PASSWORD_CLOSE,
  SET_ONBOARDING_COMPONENT_EXP_VARIATION,
  SET_ONBOARDING_COMPONENT_EXP_LOADED,
} from '@data/constants/actionTypes/onboardingComponent';

const initialState = {
  isSignUpFormOpen: false,
  isSignInFormOpen: false,
  isResetPasswordFormOpen: false,
  context: null,
  experimentVariation: null,
  experimentLoaded: false,
};

const onboardingComponent = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SIGN_IN_OPEN:
      return {
        ...state,
        isSignInFormOpen: true,
        context: action.context,
      };
    case SET_SIGN_IN_CLOSE:
      return {
        ...state,
        isSignInFormOpen: false,
        context: null,
      };
    case SET_SIGN_UP_OPEN:
      return {
        ...state,
        isSignUpFormOpen: true,
        context: action.context,
      };
    case SET_SIGN_UP_CLOSE:
      return {
        ...state,
        isSignUpFormOpen: false,
        context: null,
      };
    case SET_RESET_PASSWORD_OPEN:
      return {
        ...state,
        isResetPasswordFormOpen: true,
      };
    case SET_RESET_PASSWORD_CLOSE:
      return {
        ...state,
        isResetPasswordFormOpen: false,
        context: null,
      };
    case SET_ONBOARDING_COMPONENT_EXP_VARIATION:
      return {
        ...state,
        experimentVariation: action.value,
        experimentLoaded: true,
      };
    case SET_ONBOARDING_COMPONENT_EXP_LOADED:
      return {
        ...state,
        experimentLoaded: true,
      };
    default:
      return state;
  }
};

export default onboardingComponent;
