import { RefObject } from 'react';
import { SCROLL_VISIBILITY_ACTION_TYPES } from '../constants/actionTypes/scrollVisibility';

export type ScrollVisibilityState = {
    [key: string]: {
      isVisible: boolean;
      refElement: RefObject<HTMLElement | null>;
      observer?: IntersectionObserver;
    };
  };
// Init state should be empty
export const initialState: ScrollVisibilityState = {};

const scrollVisibilityReducer = (
  state = initialState,
  action = {
    type: '',
    payload: {
      key: '',
      isVisible: false,
      refElement: null,
      rootMargin: '0px 0px 0px 0px',
      // eslint-disable-next-line no-unused-vars
      observerCallback: (key:string, isVisible:boolean) => null,
    },
  },
) => {
  const {
    ADD_ELEMENT,
    SET_VISIBILITY,
    CLEAR_OBSERVER,
    SET_OBSERVER,
  } = SCROLL_VISIBILITY_ACTION_TYPES;

  switch (action?.type) {
    case ADD_ELEMENT: {
      const { key, refElement } = action.payload;
      if (!state[key]) {
        return {
          ...state,
          [key]: {
            refElement,
            isVisible: false,
          },
        };
      }
      return {
        ...state,
      };
    }

    case SET_VISIBILITY: {
      const { key, isVisible } = action.payload;
      return {
        ...state,
        [key]: {
          ...state[key],
          isVisible,
        },
      };
    }

    case SET_OBSERVER: {
      const { key, observerCallback, rootMargin } = action.payload;
      const visibilityItem = state[key];
      const refElement = visibilityItem.refElement?.current;
      if (refElement && !visibilityItem.observer) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (observerCallback) {
              observerCallback(key, entry.isIntersecting);
            }
          },
          { threshold: 0, rootMargin },
        );
        observer.observe(refElement);
        return {
          ...state,
          [key]: {
            ...state[key],
            observer,
          },
        };
      }
      // no new observer set
      return {
        ...state,
      };
    }

    case CLEAR_OBSERVER: {
      const { key } = action.payload;
      const visibilityItem = state[key];
      if (visibilityItem.observer) {
        visibilityItem.observer.disconnect();
        return {
          ...state,
          [key]: {
            ...state[key],
            observer: null,
          },
        };
      }
      // no observer set
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default scrollVisibilityReducer;
